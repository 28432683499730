#QualitativeResearch {
  width: 1140px;
  height: 500px;
  .contents {
    padding: 0 48px 0 0;
    .title {
      font-size: 30px;
      color: #2b2e33;
      font-family: 'Spoqa Han Sans Neo Bold';
    }

    .subtitle {
      margin-top: 40px;
      margin-bottom: 30px;
      font-size: 18px;
      color: #2b2e33;
      font-family: 'Spoqa Han Sans Neo Medium';
    }

    .selection-title {
      font-size: 18px;
      color: #818282;
      font-family: 'Spoqa Han Sans Neo Regular';
    }

    .selection-container {
      margin-top: 30px;
    }

    .selection-text,
    .ant-radio-wrapper {
      font-size: 18px;
      color: #2b2e33;
      font-family: 'Spoqa Han Sans Neo Regular';
    }

    // 라디오
    input[type='radio'],
    input[type='radio']:checked {
      width: 20px;
      height: 20px;
      margin: 0;
      outline: none;
      appearance: none;
      border-radius: 50%;
      border: 1px solid #2b2e33;
      margin-right: 0.3rem;
    }

    input[type='radio'] {
      width: 20px;
      height: 20px;
      background-color: white;
      cursor: pointer;
    }

    input[type='radio']:checked {
      background-color: white;
      border: 3px solid #f2ab27;
    }

    .radio-text {
      font-size: 18px;
      color: #2b2e33;
      font-family: 'Spoqa Han Sans Neo Regular';
      margin-left: 8px;
    }

    // 슬라이더
    .ant-slider {
      width: 332px;
    }

    .ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
      border-color: #f2ab28 !important;
      -webkit-box-shadow: 0 0 0 2px rgba(250, 198, 45, 0.2) !important;
      box-shadow: 0 0 0 2px rgba(250, 198, 45, 0.2) !important;
    }
    .ant-slider-handle:focus {
      border-color: #f2ab28 !important;
      outline: none !important;
      -webkit-box-shadow: 0 0 0 2px rgba(250, 198, 45, 0.2) !important;
      box-shadow: 0 0 0 2px rgba(250, 198, 45, 0.2) !important;
    }

    .ant-slider-dot-active {
      border-color: #f2ab28;
    }

    .ant-input-number-focused,
    .ant-input-number:focus,
    .ant-input-number:hover {
      box-shadow: 0 0 0 2px #fef4d5;
      border-color: #fac62d;
    }

    .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
    .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
      color: #fac62d;
    }

    .errorMsg {
      font-size: 14px;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      color: #ff5724;
      .small-contact-us {
        text-decoration: underline;
        color: #ff5724;
      }
    }
    // 부가 서비스
    .ant-checkbox-checked::after {
      border: 1px solid #f2ab28;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #f2ab28;
      border-color: #f2ab28;
    }

    .ant-checkout-wrapper:hover .ant-checkbox-inner {
      border-color: #f2ab28;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #f2ab28 !important;
    }
  }

  .result {
    position: sticky;
    height: 500px;
    padding: 0 0 0 96px;
    border-left: 1px solid #818282;
    top: 0;

    .title {
      color: #2b2e33;
      font-size: 30px;
      font-family: 'Spoqa Han Sans Neo Bold';
    }

    .won {
      color: #2b2e33;
      margin-left: 8px;
      font-size: 22px;
      font-family: 'Spoqa Han Sans Neo Medium';
    }

    .read-cost {
      text-align: right;
      color: #818282;
      font-size: 14px;
      margin-bottom: 14px;
    }
    .startBtn {
      position: absolute;
      bottom: 220px;
      display: block;
      width: 332px;
      height: 60px;
      background-color: #fac62d;
      border: none;
      border-radius: 3px;
      color: #2b2e33;
      font-size: 20px;
      font-family: 'Spoqa Han Sans Neo Regular';
      cursor: pointer;
    }
  }
}
