#SendingEstimate {
  width: 1140px;
  height: 500px;
  padding: 40px 48px;

  // 슬라이더
  .sending-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    .slider-input {
      height: 60px !important;
      width: 210px !important;
      text-align: center;
      border: 2px solid #e9e1d5;
      border-radius: 3px;
      margin-bottom: 10px;
    }

    .slider-input:hover,
    .slider-input:focus {
      border-color: #f2ab28 !important;
      border: 2px solid #f2ab28;
      -webkit-box-shadow: 0 0 0 2px rgba(250, 198, 45, 0.2) !important;
      box-shadow: 0 0 0 2px rgba(250, 198, 45, 0.2) !important;
    }

    .slider-input:focus {
      outline: none;
    }

    .slider-input-text {
      font-size: 30px;
      color: #2b2e33;
      font-family: 'Spoqa Han Sans Neo Regular';
    }

    .errorMsg {
      font-size: 14px;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      color: #ff5724;
    }

    .slider-sub-text {
      font-size: 18px;
      color: #2b2e33;
      font-family: 'Spoqa Han Sans Neo Regular';
      margin-bottom: 30px;
    }

    .ant-slider {
      width: 600px;
    }
  }

  .result {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
    .total-cost {
      width: 400px;
      .title {
        font-size: 30px;
        color: #2b2e33;
        font-family: 'Spoqa Han Sans Neo Bold';
        margin-bottom: 0;
      }

      .won {
        color: #2b2e33;
        margin-left: 8px;
        font-size: 22px;
        font-family: 'Spoqa Han Sans Neo Medium';
      }
    }

    .read-cost {
      text-align: right;
      color: #818282;
      font-size: 14px;
      margin-bottom: 14px;
      width: 400px;
    }
    .disclaimer {
      font-size: 14px;
      color: #818282;
      width: 100%;
      height: 200px;
      margin-top: 30px;
    }
  }
}
