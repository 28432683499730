ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: #000;
}

@font-face {
  font-family: 'Spoqa Han Sans Neo Regular';
  font-size: normal;
  font-weight: 0;
  src: url('./assets/Font/Spoqa/SpoqaHanSansNeo-Regular.otf');
}
@font-face {
  font-family: 'Spoqa Han Sans Neo Medium';
  font-size: normal;
  font-weight: 0;
  src: url('./assets/Font/Spoqa/SpoqaHanSansNeo-Medium.otf');
}
@font-face {
  font-family: 'Spoqa Han Sans Neo Bold';
  font-size: normal;
  font-weight: 0;
  src: url('./assets/Font/Spoqa/SpoqaHanSansNeo-Bold.otf');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo Light';
  font-size: normal;
  font-weight: 0;
  src: url('./assets/Font/Spoqa/SpoqaHanSansNeo-Light.otf');
}
