p {
  margin-bottom: 0;
}
#PanelResearch {
  width: 1140px;
  height: 800px;
  .contents {
    padding: 0 48px 0 0;
    .title {
      font-size: 30px;
      color: #2b2e33;
      font-family: 'Spoqa Han Sans Neo Bold';
    }

    .subtitle {
      margin-top: 40px;
      font-size: 20px;
      color: #2b2e33;
      font-family: 'Spoqa Han Sans Neo Medium';
    }

    .selection-container {
      margin-top: 30px;
    }
    .selection-title {
      font-size: 18px;
      color: #818282;
      font-family: 'Spoqa Han Sans Neo Regular';
    }

    .selection-text,
    .ant-radio-wrapper {
      font-size: 18px;
      color: #2b2e33;
      font-family: 'Spoqa Han Sans Neo Regular';
    }

    // 슬라이더
    .ant-slider {
      width: 332px;
    }

    .ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
      border-color: #f2ab28 !important;
      -webkit-box-shadow: 0 0 0 2px rgba(250, 198, 45, 0.2) !important;
      box-shadow: 0 0 0 2px rgba(250, 198, 45, 0.2) !important;
    }
    .ant-slider-handle:focus {
      border-color: #f2ab28 !important;
      outline: none !important;
      -webkit-box-shadow: 0 0 0 2px rgba(250, 198, 45, 0.2) !important;
      box-shadow: 0 0 0 2px rgba(250, 198, 45, 0.2) !important;
    }

    .ant-slider-dot-active {
      border-color: #f2ab28;
    }

    .ant-input-number-focused,
    .ant-input-number:focus,
    .ant-input-number:hover {
      box-shadow: 0 0 0 2px #fef4d5;
      border-color: #fac62d;
    }

    .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
    .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
      color: #fac62d;
    }

    .errorMsg {
      font-size: 14px;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      color: #ff5724;
      .small-contact-us {
        text-decoration: underline;
        color: #ff5724;
      }
    }

    .region-container {
      margin-bottom: 12px;
    }
    .desc-msg {
      color: #818282;
      margin-bottom: 20px;
      .small-contact-us {
        text-decoration: underline;
        color: #818282;
      }
    }

    .region-button {
      // width: 70px;
      height: 32px;
      margin-right: 14px;
      margin-bottom: 8px;
      border: 1px solid #dfdedd;
      border-radius: 16px;
      background-color: white;
      outline: none;

      .region-button:focus {
        outline: none;
      }

      &.button-en {
        width: 140px;
      }

      &.button-ko {
        width: 70px;
      }
    }

    .region-checked {
      border: 1px solid #fac62d;
      background-color: #fac62d;
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0px;
    }

    // 표본 심화 조건 더보기
    .additional-conditions {
      font-family: 'Spoqa Han Sans Neo Regular';
      font-size: 18px;
      color: #2b2e33;
      .container {
        font-size: 14px;

        .contents-container {
          width: 619px;
          min-height: 305px;
          margin-top: 30px;
          padding: 30px 30px 10px 30px;
          border: 1px solid #dfdedd;
          border-radius: 3px;

          .info-title {
            color: #2b2e33;
            margin-bottom: 10px;
            font-family: 'Spoqa Han Sans Neo Medium';
          }

          .info-button-container {
            margin-bottom: 20px;

            .info-button {
              height: 32px;
              margin-right: 14px;
              margin-bottom: 10px;
              padding: 0 14px;
              border: 1px solid #dfdedd;
              border-radius: 16px;
              background-color: #fff;
              outline: none;
              cursor: pointer;

              &:focus {
                outline: none;
              }
            }
            .info-checked {
              border: 1px solid #fac62d;
              background-color: #fac62d;
            }
          }
        }
        .action-button {
          margin-top: 20px;
          margin-bottom: 12px;
          width: 120px;
          height: 50px;
          outline: none;
          color: #818282;
          background-color: #f0f0f0;
          border: none;
          border-radius: 3px;
        }

        .action-button-active {
          background-color: #fac62d;
          border: 1px solid #fac62d;
          color: #2b2e33;
          cursor: pointer;
        }
        .action-button:focus {
          outline: none;
        }
      }
    }

    // 콜랍스
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding-left: 30px;
    }

    .ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      left: 0px;
    }
    // 부가 서비스
    .ant-checkbox-checked::after {
      border: 1px solid #f2ab28;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #f2ab28;
      border-color: #f2ab28;
    }

    .ant-checkout-wrapper:hover .ant-checkbox-inner {
      border-color: #f2ab28;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #f2ab28 !important;
    }
  }

  .result {
    position: sticky;
    height: 800px;
    padding: 0 0 0 96px;
    border-left: 1px solid #818282;
    top: 0;

    .result-content {
      height: 500px;
      position: relative;
      .title {
        color: #2b2e33;
        font-size: 30px;
        font-family: 'Spoqa Han Sans Neo Bold';
      }

      .won {
        color: #2b2e33;
        margin-left: 8px;
        font-size: 22px;
        font-family: 'Spoqa Han Sans Neo Medium';
      }

      .read-cost {
        text-align: right;
        color: #818282;
        font-size: 14px;
        margin-bottom: 14px;
      }
      .startBtn {
        position: absolute;
        bottom: 220px;
        display: block;
        width: 332px;
        height: 60px;
        background-color: #fac62d;
        border: none;
        border-radius: 3px;
        color: #2b2e33;
        font-size: 20px;
        font-family: 'Spoqa Han Sans Neo Regular';
        cursor: pointer;
      }
    }
  }
}
